import { createGlobalStyle } from "styled-components";
import { flexUnit } from "../mixins";

const GlobalStyle = createGlobalStyle`
  /* montserrat-500 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: local(''),
        url('/fonts/montserrat-v25-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/montserrat-v25-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  body {
    color: ${props => props.theme.dark};
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    ${flexUnit(2, 15, 20, "vw", "font-size")}
    line-height: 1.7em;
    letter-spacing: 0.4px;
    font-weight: 400;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-kerning: normal;
    font-variant-ligatures: contextual;
    font-feature-settings: "lnum" 1, "pnum" 1, "kern" 1, "liga" 1;
  }

  h1, h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: 0;
  }

  h1 {
    ${flexUnit(5, 26, 60, "vw", "font-size")}
  }

  h2 {
    ${flexUnit(4, 20, 36, "vw", "font-size")}

    &.sectionHeadline {
      margin-top: 0;
      margin-bottom: 5vh;
    }
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.red};
    transition: color 200ms ease;

    &:hover {
      color: #911D22;
    }
  }

  figure {
    margin: 0;
  }

  .padding {
    ${flexUnit(5, 20, 80, "vw", "padding")}
  }
`;

export default GlobalStyle;
